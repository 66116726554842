import React from 'react'
import AudienceView from './AudienceView'
import smImage from '../../assets/images/hero-training-sm.png'
import mdImage from '../../assets/images/hero-training-md.png'
import lgImage from '../../assets/images/hero-training.png'

const trainingItems = [
  { text: 'key-benefits:training:item1' },
  { text: 'key-benefits:training:item2' },
  { text: 'key-benefits:training:item3' },
  { text: 'key-benefits:training:item4' },
  { text: 'key-benefits:training:item5' },
  { text: 'key-benefits:training:item6' },
]

const Trainingiew = () => {
  return (
    <>
      <AudienceView
        page="training"
        title="use-case:training:title"
        subtitle="noun:training"
        buttonText="action:see-case-study"
        smImage={smImage}
        mdImage={mdImage}
        lgImage={lgImage}
        videoTitle="Aucta training video"
        videoUrl="https://www.youtube.com/embed/StNpW19XCho"
        items={trainingItems}
        buttonTo="/alphr"
      />
    </>
  )
}

export default Trainingiew
